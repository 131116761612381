module.exports = [{
      plugin: require('../node_modules/_gatsby-plugin-image@1.5.0@gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/_gatsby-plugin-catch-links@3.5.0@gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/_gatsby-source-wordpress@5.5.0@gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://api.sunnwell.com/graphql","verbose":true,"debug":{"timeBuildSteps":true,"preview":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"production":{"hardCacheMediaFiles":true,"allow404Images":true},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"type":{"Post":{"limit":5000},"Page":{"limit":5000},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../node_modules/_gatsby-plugin-google-analytics@3.5.0@gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-138128439-2","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"optimizeId":"YOUR_GOOGLE_OPTIMIZE_TRACKING_ID","experimentId":"YOUR_GOOGLE_EXPERIMENT_ID","variationId":"YOUR_GOOGLE_OPTIMIZE_VARIATION_ID","sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"sunnwell.com"},
    },{
      plugin: require('../node_modules/_gatsby-plugin-manifest@3.5.0@gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SUNNWELL","short_name":"S&W","start_url":"/","background_color":"#c2f2ec","theme_color":"#c2f2ec","display":"minimal-ui","icon":"src/images/swlogo-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f051b5b1a1c9ac0e717e7a20e09dd351"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
