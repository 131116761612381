// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meetus-en-js": () => import("./../../../src/pages/meetus.en.js" /* webpackChunkName: "component---src-pages-meetus-en-js" */),
  "component---src-pages-meetus-js": () => import("./../../../src/pages/meetus.js" /* webpackChunkName: "component---src-pages-meetus-js" */),
  "component---src-pages-news-en-js": () => import("./../../../src/pages/news.en.js" /* webpackChunkName: "component---src-pages-news-en-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-en-js": () => import("./../../../src/pages/privacy.en.js" /* webpackChunkName: "component---src-pages-privacy-en-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-en-js": () => import("./../../../src/pages/services.en.js" /* webpackChunkName: "component---src-pages-services-en-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-works-en-js": () => import("./../../../src/pages/works.en.js" /* webpackChunkName: "component---src-pages-works-en-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-templates-news-post-en-js": () => import("./../../../src/templates/newsPost.en.js" /* webpackChunkName: "component---src-templates-news-post-en-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/newsPost.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-works-post-en-js": () => import("./../../../src/templates/worksPost.en.js" /* webpackChunkName: "component---src-templates-works-post-en-js" */),
  "component---src-templates-works-post-js": () => import("./../../../src/templates/worksPost.js" /* webpackChunkName: "component---src-templates-works-post-js" */)
}

