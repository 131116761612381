require("./src/assets/css/animate.min.css")

const React = require("react")
const WOW = require("wowjs")

exports.wrapPageElement = ({ element, props }) => {

  if (typeof window !== undefined) {
      window.wow = new WOW.WOW({
        live: false,
      })
  }
  return <React.Fragment>{element}</React.Fragment>
}